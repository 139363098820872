import './App.css';
import { RouterDom } from './router/RouterDom';


function App() {
    
  return (
    <>
    <RouterDom />
    
    </>
  );
}

export default App;
